<template>
  <div class="m-menu__wrapper">
    <div class="block block__right">
      <Burger fill="white" @click="hideMenu" />
    </div>
    <div class="m-menu__container">
      <div class="m-menu__user"></div>
      <div class="m-menu__menu_title">
        {{header.menu[language]}}
      </div>
      <div class="m-menu__menu">
        <div class="m-menu__buttons">
          <!--<FileButton :text="header.button2[language]" @close="hideMenu" />-->
          <Button
            v-if="user"
            class="m-menu__button"
            :text="header.personal_account[language]"
            @click="pushToLk" />
          <Button
            v-if="!user"
            class="m-menu__button"
            :text="header.button3[language]"
            @click="showAuthModal" />
          <Button
            v-if="user"
            class="m-menu__button"
            :text="header.button5[language]"
            @click="signOut" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import header from '@/assets/text/header.json';
import Burger from '@/assets/pictures/header/Burger.svg';

const Button = () => import('./MenuButton.vue');
// const FileButton = () => import('./MenuFileButton.vue');

export default {
  name: 'MobileMenu',
  data() {
    return {
      header,
      token: null,
    };
  },
  methods: {
    hideMenu() {
      this.$emit('hideMenu');
    },
    setToken(token) {
      this.token = token;
    },
    showAuthModal() {
      this.$store.commit('user/setShowAuthorizeModal', true);
      this.$store.commit('user/setAfterAuthorize', () => {
        this.pushToLk();
      });
    },
    signOut() {
      this.$store.commit('user/removeUser');
      this.$store.commit('user/removeAccess');
      localStorage.removeItem('token');
      this.token = null;
      this.$router.push('/');
      this.hideMenu();
    },
    pushToLk() {
      this.hideMenu();
      this.$router.push('/orders/draft');
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  created() {
    const secretKey = localStorage.getItem('token');
    if (secretKey) {
      this.token = secretKey;
    }
  },
  beforeDestroy() {
    this.$store.commit('user/setAfterAuthorize', () => {});
  },
  components: {
    Burger,
    Button,
    // FileButton,
  },
};
</script>
